<template functional>
  <div 
    :style="`margin-bottom:${props.spaceBottom}px; margin-top:${props.spaceTop}px;`"
    class="icon-list"
  >
    <div 
      v-for="(item, i) in props.listItems"
      :key="i"
      class="icon-list__item"
    > 
      <img 
        class="icon-list__icon" 
        :src="item.icon" 
        :alt="item.altTag"
      > 
      <!-- eslint-disable vue/no-v-html -->
      <p class="icon-list__content" v-html="item.content" />
      <!--eslint-enable-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconList',
  props: {
    listItems: {
      type: Array,
      required: true
    },
    spaceTop: {
      type: Number,
      default: 0
    },
    spaceBottom: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-list {
  text-align: left;
  
  &__content {
    margin: 0;
    color: #545454;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__icon {
    margin-right: 8px;
  }
  
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
