<template>
  <svg
    width="15px"
    height="14px"
    version="1.1"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
    :class="fillColor"
  >
    <title>right arrow</title>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(.17006)" fill="currentColor" fillColor-rule="nonzero">
        <path transform="translate(7 7) scale(-1 1) translate(-7 -7)" d="m5.1692 1 0.87661 0.71451-4.1049 4.7855h12.059v1h-12.128l4.0254 4.7944-0.88414 0.70563-5.0133-5.9713 5.1692-6.0287z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowRight',
  props: {
    fillColor: {
      type: String,
      default: 'white'
    }
  }
}
</script>
