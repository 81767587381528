<template>
  <div :class="getPartner.name">
    <NavbarJourney
      :telephone="getPhone"
      :friendly-telephone="getFriendlyPhone"
      :telephone-color="dynamicColor"
    />
    <div class="container affiliate-page affiliate-page--pad-top fade-in">
      <QuestionTitle
        :title="title"
        subtitle="Based on the information you have provided we think you would be a good match with the following lender:"
        :title-color-class="titleColor"
      />
      <div class="affiliate-page__containers">
        <div v-for="(card, i) in cardItems" :key="i" class="input-container affiliate-page__container">
          <div class="affiliate-page__logo">
            <component :is="card.logo" />
          </div>
  
          <IconList
            class="affiliate-page__icon-list"
            :list-items="card.listItems"
            :space-bottom="27"
            :space-top="29"
          />
          <InfoList :info-list-content="card.infoListContent" />
          <a target="_blank" :href="`${card.link}`" class="affiliate-page__link">
            <Button
              class="affiliate-page__btn"
              :class-prop="buttonBg"
              :is-squared-button="true"
              :button-rounded-lg="isMoneyTest"
              :right-arrow="true"
              :right-arrow-color="dynamicColor"
              :hover-state="true"
              :text="'Continue'"
              @click="affiliateClick"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moneyFormat from '@/helpers/filters/moneyFormat'
import NavbarJourney from '@/components/shared/NavbarJourney'
import QuestionTitle from '@/components/question/QuestionTitle'
import IconList from '@/components/shared/IconList'
import InfoList from '@/components/shared/InfoList'
import SvgIwoca from '@/components/shared/svg/SvgIwoca'
import SvgYouLend from '@/components/shared/svg/SvgYouLend'
import SvgNationwide from '@/components/shared/svg/SvgNationwide'
import Button from '@/components/shared/Button'
import { IWOCA_LINK, YOU_LEND_LINK, NATIONWIDE_LINK } from '@/helpers/constants/affiliate-links'
import { mapGetters } from 'vuex'
import instrument from '@/helpers/instrument'

export default {
  name: 'AffiliatePage',
  components: {
    NavbarJourney,
    IconList,
    InfoList,
    SvgIwoca,
    SvgNationwide,
    SvgYouLend,
    Button,
    QuestionTitle
  },
  filters: {
    moneyFormat
  },
  data () {
    return {
      firstName: null
    }
  },
  computed: {
    ...mapGetters([
      'getPhone',
      'getFriendlyPhone',
      'customerFirstName',
      'buttonBg',
      'isMoneyTest',
      'dynamicColor',
      'titleColor',
      'getPartner'
    ]),
    title () {
      return `Hi ${this.firstName}, we’ve found an option for you.`
    },
    partnerNameCheck () {
      return this.getPartner.name || 'compare'
    },
    cardItems () {
      return [
        {
          logo: 'SvgIwoca',
          listItems: [
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'No fees for early repayment'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'Decision within 24 hours'
            }
          ],
          infoListContent: [
            {
              bold: 'Loan amount',
              light: '£1,000 - £200,000'
            },
            {
              bold: 'Loan type',
              light: 'Flexi-Loan'
            },
            {
              bold: 'Loan term',
              light: '1 to 12 months'
            }
          ],
          link: `${IWOCA_LINK}${this.partnerNameCheck}`
        },
        {
          logo: 'SvgNationwide',
          listItems: [
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'All business types welcome'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'Same day decision'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'No penalties for settling early'
            }
          ],
          infoListContent: [
            {
              bold: 'Loan amount',
              light: '£6,000 - £10,000,000'
            },
            {
              bold: 'Loan type',
              light: 'Business loan'
            },
            {
              bold: 'Loan term',
              light: '1 to 6 years'
            }
          ],
          link: `${NATIONWIDE_LINK}${this.partnerNameCheck}`
        },
        {
          logo: 'SvgYouLend',
          listItems: [
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'You MUST have a physical card machine (POS Terminal) in your premises that generates over £5k per month in <b>Card Sales</b>'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: '<b>Card Sales</b> are when your customers pay in store via a Debit/Credit Card'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'Repayments in line with future <b>Card Sales</b>'
            },
            {
              icon: require('@/assets/icons/tick-grey.png'),
              altTag: 'grey tick',
              content: 'Minimum 3 months trade'
            }
          ],
          infoListContent: [
            {
              bold: 'Loan amount',
              light: ' £5,000 - £1,000,000'
            },
            {
              bold: 'Loan type',
              light: 'Merchant Cash Advance'
            }
          ],
          link: `${YOU_LEND_LINK}${this.partnerNameCheck}.thinkbusinessloans.com`
        }
      ]
    }
  },
  mounted () {
    this.setLocalStorageCustomerFirstName()
    this.getLocalStorage('firstName')
  },
  methods: {
    getLocalStorage (localStorageName) {
      // if there is an item in local storage
      if (localStorage.getItem(localStorageName)) {
        // set amount
        this[localStorageName] = localStorage.getItem(localStorageName)
      }
    },
    setLocalStorageCustomerFirstName () {
      // if customer name has been set
      if (this.customerFirstName) {
        // store in local storage
        localStorage.setItem('firstName', this.customerFirstName)
      }
    },
    affiliateClick () {
      // send to data layer for google analytics/optimise tracking
      instrument.iwocaAffiliateClick()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  @media (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.affiliate-page {

  &__logo {
    min-height: 50px;
  }

  &__link {
    text-decoration: none;
    margin-top: auto;

    &:hover {
      text-decoration: none;
    }
  }

  &--pad-top {
    margin-top: 35px;

    @media (min-width: 1000px) {
      margin-top: 50px;
    }
  }

  &__containers {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    @media (max-width: $screen-lg) {
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 36px 31px 36px;
    max-width: 451px;
    width: 451px;
    min-height: 416px;
    height: 510px;
    margin: 10px;

    @media (max-width: $screen-md) {
      width: 100%;
    }

    @media (min-width: $screen-md) {
      padding: 34px 36px 5px 36px;
    }
  }

}
</style>

<style lang="scss">
.affiliate-page {
  &__btn {
    .button {
      width: 202px !important;
      height: 42px !important;
    }
  }
}
</style>
