<template functional>
  <div class="info-list">
    <div 
      v-for="(item, i) in props.infoListContent"
      :key="i"
      class="info-list__item"
    >
      <p class="info-list__content">
        <span class="info-list__content--bold">{{ item.bold }}</span>:
        <span class="info-list__content--light">{{ item.light }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoList',
  props: {
    infoListContent: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-list {

    &__item {
      margin-bottom: 5px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__content {
      line-height: 20px;
      color: $blue-4;
      font-size: 14px;
      margin: 0;

      &--bold {
        font-weight: bold;
      }

      &--light {
        font-weight: light;
      }

    }
  }
</style>
