<template>
  <div class="wrapper" :class="[roundedButton, squaredButton]">
    <button
      class="button"
      :class="[isWorkingClass, classProp, isHoverState]"
      :disabled="disabled"
      @click="click"
    >
      <span v-if="!isWorking" class="button-text" :class="rightArrowColor">
        {{ text }}
      </span>
      <span v-if="rightArrow" class="button__right-arrow">
        <SvgArrowRight :fill-color="rightArrowColor" />
      </span>
      <div
        v-if="isWorking"
        class="working"
        :class="[getPartner ? 'working--grey' : ' ']"
      />
    </button>
  </div>
</template>

<script>
import SvgArrowRight from '@/components/shared/svg/SvgArrowRight'
import { mapGetters } from 'vuex'
export default {
  components: {
    SvgArrowRight
  },
  props: {
    text: {
      type: String,
      default: 'Next'
    },
    disabled: {
      type: Boolean
    },
    isWorking: {
      type: Boolean,
      default: false
    },
    classProp: {
      type: String,
      default: ''
    },
    rightArrow: {
      type: Boolean,
      default: false
    },
    buttonRounded: {
      type: Boolean,
      default: false
    },
    buttonRoundedLg: {
      type: Boolean,
      default: false
    },
    hoverState: {
      type: Boolean,
      default: false
    },
    isSquaredButton: {
      type: Boolean,
      default: false
    },
    rightArrowColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['getPartner']),
    isHoverState () {
      if (!this.hoverState) return ''
      return 'button--hover'
    },
    isWorkingClass () {
      if (!this.isWorking) return ''
      return 'working'
    },
    roundedButton () {
      if (this.buttonRounded) return 'button--rounded'
      if (this.buttonRoundedLg) return 'button--rounded-lg'
      return ''
    },
    squaredButton () {
      if (!this.isSquaredButton) return ''
      return 'button--squared'
    }
  },
  methods: {
    click ($event) {
      if (this.isWorking) {
        return
      }

      this.$emit('click', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: 240px;
  height: 56px;
  justify-content: center;
}

.button--squared {
  min-width: 128px;
  width: 128px;
  .button {
    height: 52px;
    border-radius: 5px;
  }
}

.button--inline {
  margin-top: 24px;
  @media (min-width: 801px) {
    margin-left: auto;
    margin-right: -20px;
  }

  @media (max-width: $screen-md) {
    margin-left: auto;
    margin-top: 24px;
    width: 96px;
    height: 42px;
    min-width: 96px;
  }
}

.button--rounded-lg {
  .button {
      border-radius: 28px;
  }
}

.button--rounded {
  .button {
    @media (max-width: $xs-width) {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      margin-top: 5px;
    }

    &-text {
      @media (max-width: $xs-width) {
        display: none;
      }
    }
  }
}

.button {
  height: 56px;
  width: 220px;
  border-radius: 28px;
  background-color: $text-color-orange;
  border: 0;
  padding: 0;
  margin: 0;
  transition: -webkit-transform 0.2s ease-out;

  &--hover {
    &:hover {
      transform: translate3d(0, -4px, 0);
    }
  }

  span {
    color: white;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }

  &:focus {
    outline: 0;
  }

  &__right-arrow {
    @media (min-width: $md-width) {
      margin-left: 2px;
    }
  }
}

button.working {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  cursor: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: translate3d(0, 0, 0);
  }
}

div.working {
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border-top: 3px solid $text-color-orange;
  border-right: 3px solid $text-color-orange;
  border-bottom: 3px solid $text-color-orange;
  border-left: 3px solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear;

  &--grey {
    border-top: 3px solid $color-grey-1;
    border-right: 3px solid $color-grey-1;
    border-bottom: 3px solid $color-grey-1;
  }
}

.bionic-blue-bg {
  div.working {
    border-top: 3px solid $blue-1;
    border-right: 3px solid $blue-1;
    border-bottom: 3px solid $blue-1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
